<template>
  <div class="admin">
    <div class="menu">
      <admin-menu v-if="showLeftMenu"></admin-menu>
    </div>
    <div class="content">
      <div class="top-bar">
        <app-header-dropdown admin></app-header-dropdown>
      </div>
      <div class="bottom-info">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import AdminMenu from './components/AdminMenu';
import { AppHeaderDropdown } from '@/components/layout';
import { computed } from 'vue';

export default {
  components: {
    AdminMenu,
    AppHeaderDropdown
  },
  setup() {
    /**
     * 需求是：
     * 除了有权限进入后台管理的角色可以查看「无人机任务列表」页面之外
     * 可能会有个别角色只具有查看「无人机任务列表」的权限，但不具有查看后台其他页面的权限
     * 所以这里判断，如果获取到的顶部菜单有「无人机任务列表」，那就隐藏左侧菜单栏
     * 临时方案，后面需要根据情况动态修改后台路由
     */
    const showLeftMenu = computed(() => {
      let menuList = JSON.parse(localStorage.getItem('authMenuList'));
      let flag = true;
      menuList.map(item => {
        if (item.component === 'admin/machine-manage/uav-task-list') {
          flag = false;
        }
      });
      return flag;
    });

    return {
      showLeftMenu
    };
  }
};
</script>

<style lang="less" scoped>
.admin {
  display: flex;
  .menu {
    width: 200px;
    height: 100vh;
    background-color: #f1f1f1;
    overflow: hidden;
    &:hover {
      overflow: overlay;
    }
  }
  .content {
    width: calc(100vw - 200px);
    background-color: #f1f1f1;
    .top-bar {
      height: 66px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 50px;
      border-bottom: 1px solid #ccc;
      background-color: #fff;
    }
    .bottom-info {
      height: calc(100vh - 66px);
      overflow: hidden;
      &:hover {
        overflow: overlay;
      }
    }
  }
}
</style>
